@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap");

body,
html {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

input,
textarea,
select,
button {
  -webkit-tap-highlight-color: transparent;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
