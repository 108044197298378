.accordion-header h2 {
  display: flex;
  justify-content: space-between;
}

.accordion-subinfo {
  font-size: 21px;
  font-weight: 1000;
  width: 35%;
  text-align: right;
  padding-right: 2%;
}

.accordion-subinfo-title {
  display: none;
}
